import { inject } from "@angular/core";
import { Router, ActivatedRouteSnapshot, CanActivateFn } from "@angular/router";

import { combineLatest } from "rxjs";
import { defaultIfEmpty, filter, map, startWith, switchMap } from "rxjs/operators";

import { CodmanUserAuthorizationService } from "./codman-user-authorization.service";
import { organizationIdUrlParam } from "@codman/shared/types";

const NO_ORGANIZATION = "empty-organization";

export const overviewUserAuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot) => {
    const authorizationService = inject(CodmanUserAuthorizationService);
    const router = inject(Router);

    const organizationIdFromQueryParams = next.queryParamMap.get(organizationIdUrlParam);

    return combineLatest([
        authorizationService.getOrganizationsByRegistryId(null),
        authorizationService.organizationId$.pipe(startWith(NO_ORGANIZATION)),
    ]).pipe(
        filter(([organizations, organizationId]) => {
            let organization = organizations.find(o => o.cicCode === organizationId);
            if (organization != null) {
                return true;
            }

            organization = organizations.find(
                o => o.organizationId === Number(organizationIdFromQueryParams),
            );

            // if no intial organization or it is not found in valic cic codes
            // use default and do not continue the pipe
            authorizationService.setDefaultOrganization(
                organizations,
                organization && organization.cicCode
                    ? organization.cicCode?.toString()
                    : organizationIdFromQueryParams,
            );
            return false;
        }),
        switchMap(() => authorizationService.overviewRegistryPermissions$),
        map(permissions => permissions.length > 0),
        defaultIfEmpty(false),
        map(hasAccess => {
            if (!hasAccess) {
                return router.parseUrl("access-denied");
            }
            return hasAccess;
        }),
    );
};
