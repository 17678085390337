import { inject, Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
    IAppSession,
    LG_APP_CONFIGURATION,
    LG_APP_SESSION,
    ServerGatewayBase,
} from "@logex/framework/lg-application";

import { ORG_ID_HEADER } from "./data-access-shared-api.types";
import { CodmanUserAuthorizationService } from "@codman/shared/data-access-authorization";
import {
    DbFilterSetStateResponse,
    FilterSetState,
    mapFiltersetStateFromDb,
    mapFiltersetStateToDb,
} from "@logex/framework/lg-layout";

const BASE_API_PATH = "api/filterset/states";
const GET_SUFFIX = "all";
const SAVE_SUFFIX = "save";
const DELETE_SUFFIX = "delete";

@Injectable({ providedIn: "root" })
export class CodmanFiltersetStateGateway extends ServerGatewayBase {
    private _session: IAppSession = inject(LG_APP_SESSION);
    private _authorizationService = inject(CodmanUserAuthorizationService);

    _organizationId: number | undefined;
    _accountId: number | undefined;

    constructor() {
        super();
        this._setBaseUrl(inject(LG_APP_CONFIGURATION).applicationRoot);
        this._authorizationService.organizationId$.subscribe(organizationId => {
            this._organizationId = organizationId;
        });

        this._authorizationService.userProfile$.subscribe(userProfile => {
            this._accountId = userProfile?.accountId;
        });
    }

    getCurrentBookmarks$(filterHostId: string): Observable<FilterSetState[]> {
        return this._get<DbFilterSetStateResponse>(
            `${BASE_API_PATH}/${GET_SUFFIX}/${filterHostId}`,
            {
                params: {
                    clientId: this._accountId,
                    scenarioId: this._session.scenarioId,
                },
                headers: this._organizationId
                    ? {
                          [ORG_ID_HEADER]: this._organizationId.toString(),
                      }
                    : {},
            },
        ).pipe(map(data => mapFiltersetStateFromDb(data)));
    }

    load(filterHostId: string): Observable<FilterSetState[]> {
        return this.getCurrentBookmarks$(filterHostId);
    }

    save(set: FilterSetState): Observable<string> {
        return this._post<string>(`${BASE_API_PATH}/${SAVE_SUFFIX}`, {
            body: {
                clientId: this._accountId,
                scenarioId: this._session.scenarioId,
                ...mapFiltersetStateToDb(set),
            },
            headers: this._organizationId
                ? {
                      [ORG_ID_HEADER]: this._organizationId.toString(),
                  }
                : {},
        });
    }

    deleteState(stateId: string): Observable<void> {
        return this._delete<void>(`${BASE_API_PATH}/${DELETE_SUFFIX}`, {
            params: {
                clientId: this._accountId,
                state_id: stateId,
            },
            headers: this._organizationId
                ? {
                      [ORG_ID_HEADER]: this._organizationId.toString(),
                  }
                : {},
        });
    }
}
