import { inject } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    CanActivateFn,
} from "@angular/router";

import { combineLatest, of } from "rxjs";
import { defaultIfEmpty, filter, map, startWith, switchMap, tap } from "rxjs/operators";

import {
    SharedConfigService,
    SharedLocalizationSettingsService,
} from "@codman/shared/util-logex-framework-setup";
import { englishOnlyRegistries, REGISTRY_TENANTS_LOOKUP } from "@codman/shared/assets";

import { CodmanUserAuthorizationService } from "./codman-user-authorization.service";
import { organizationIdUrlParam } from "@codman/shared/types";

const NO_ORGANIZATION = "empty-organization";

export const codmanUserAuthGuard: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const authorizationService = inject(CodmanUserAuthorizationService);
    const router = inject(Router);
    const configService = inject(SharedConfigService);
    const localizationService = inject(SharedLocalizationSettingsService);

    const currentRegistryId = next.paramMap.get("registryId");
    if (!currentRegistryId) return of(false);

    const organizationIdFromQueryParams = next.queryParamMap.get(organizationIdUrlParam);
    return configService.configuration$.pipe(
        switchMap(configuration => {
            const tenant = configuration.tenant;

            if (
                tenant != null &&
                (!currentRegistryId || !REGISTRY_TENANTS_LOOKUP[tenant].includes(currentRegistryId))
            ) {
                const defaultRegistry = REGISTRY_TENANTS_LOOKUP[tenant][0];
                return of(router.parseUrl(`/${defaultRegistry}`));
            }

            return combineLatest([
                authorizationService.getOrganizationsByRegistryId(currentRegistryId),
                authorizationService.organizationId$.pipe(startWith(NO_ORGANIZATION)),
                authorizationService.currentOrganizationPermissions$.pipe(startWith([])),
            ]).pipe(
                filter(([organizations, organizationId]) => {
                    let organization = organizations.find(o => o.cicCode === organizationId);
                    if (organization != null) {
                        return true;
                    }

                    organization = organizations.find(
                        o => o.organizationId === Number(organizationIdFromQueryParams),
                    );

                    // if no intial organization or it is not found in valic cic codes
                    // use default and do not continue the pipe
                    authorizationService.setDefaultOrganization(
                        organizations,
                        organization && organization.cicCode
                            ? organization.cicCode?.toString()
                            : organizationIdFromQueryParams,
                    );
                    return false;
                }),
                tap(() => {
                    if (englishOnlyRegistries.includes(currentRegistryId))
                        localizationService.setPreferredLanguage("en-GB");
                }),
                switchMap(() => authorizationService.availableRegistries$),
                map(
                    registryIds =>
                        !!registryIds.find(registryId => registryId === currentRegistryId),
                ),
                defaultIfEmpty(false),
                map(hasAccess => {
                    if (!hasAccess) {
                        return router.parseUrl("access-denied");
                    }
                    return hasAccess;
                }),
            );
        }),
    );
};
