import { inject, Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { combineLatest, Observable, of, ReplaySubject } from "rxjs";
import {
    catchError,
    distinctUntilChanged,
    first,
    map,
    shareReplay,
    switchMap,
} from "rxjs/operators";
import { Dictionary, flatten, groupBy, uniq, uniqBy } from "lodash";

import {
    LG_APP_INFO,
    IAppInfo,
    LgMatTrackingService,
    LG_USER_INFO,
} from "@logex/framework/lg-application";
import { IProduct } from "@logex/framework/lg-layout";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { IDropdownDefinition } from "@logex/framework/ui-core";

import {
    AuthorizationExtendedPermission,
    AuthorizationOrganization,
    DataAccessAuthorizationApiService,
    OrganizationIdentifier,
} from "@codman/shared/data-access-authorization-api";
import {
    CodmanApplication,
    InsightsApplication,
    MRDM_PRODUCTS,
    RegistryTenant,
} from "@codman/shared/assets";
import { CodmanEventTracer } from "@codman/shared/util-tracking";
import { SharedConfigService } from "@codman/shared/util-logex-framework-setup";

import { AuthRegistryInfo, Tenants } from "./codman-user-authorization.types";
import { createProductCode, parseProductCode } from "./product-code";
import { getUniqRegistriesFromPermissions } from "./registry";
import {
    SharedAppUser,
    APP_CA_INSTANCE_LOOKUP,
    APP_INSTANCE_LOOKUP,
} from "@codman/shared/util-logex-framework-setup";
import { AppConfiguration, organizationIdUrlParam } from "@codman/shared/types";

const ALL_CODMAN_APP_INSTANCES = Object.values(APP_INSTANCE_LOOKUP)
    .filter(instance => instance)
    .concat("ApplicationInstance.CodmanMedicine.NL");

const ALL_CODMAN_CA_APP_INSTANCES = Object.values(APP_CA_INSTANCE_LOOKUP)
    .filter(instance => instance)
    .concat("ApplicationInstance.CodmanMedicine.CA");

export interface ITenantWithRegistries {
    id: RegistryTenant;
    registries: AuthRegistryInfo[];
}

@Injectable({
    providedIn: "root",
})
export class CodmanUserAuthorizationService {
    private _authorizationApiService = inject(DataAccessAuthorizationApiService);
    private _sharedConfigService = inject(SharedConfigService);
    private _lgTranslateService = inject(LgTranslateService);
    private _router = inject(Router);
    _tracer = inject(CodmanEventTracer);
    _matomo = inject(LgMatTrackingService);

    readonly organizationIdentifierType: OrganizationIdentifier = "Cic";

    readonly currentAppInstance = this._lgAppInfo.toolInstanceName
        ? this._lgAppInfo.toolInstanceName
        : undefined;

    readonly userProfile$ = this._authorizationApiService.getProfile().pipe(shareReplay(1));

    readonly allUserOrganizations$ = this._getAllUserOrganizations$();

    private readonly _organizationId$ = new ReplaySubject<number>(1);
    readonly organizationId$ = this._organizationId$.pipe(distinctUntilChanged());

    private readonly _organizationLgId$ = new ReplaySubject<number>(1);
    readonly organizationLgId$ = this._organizationLgId$.pipe(distinctUntilChanged());

    private readonly _organizationName$ = new ReplaySubject<string>(1);
    readonly organizationName$ = this._organizationName$.pipe(distinctUntilChanged());

    private readonly _organizationUserPosition$ = new ReplaySubject<string>(1);
    readonly organizationUserPosition$ =
        this._organizationUserPosition$.pipe(distinctUntilChanged());

    readonly mrdmProducts$ = this._getMrdmProducts$();

    readonly allOrganizationsPermissions$ = this._getAllOrganizationsPermissions$(
        this.allUserOrganizations$,
    );

    readonly permissionsByOrganization$ = this.allOrganizationsPermissions$.pipe(
        map(permissions => groupBy(permissions, "cicCode")),
    );

    /**
     * Organizations with at least one Codman application instance permission
     */
    readonly userOrganizations$ = this._getRegistryOrganizations$(
        null,
        this.currentAppInstance ? [this.currentAppInstance] : undefined,
    );

    readonly currentOrganizationPermissions$ = this.organizationId$.pipe(
        switchMap(organizationId => this.getPermissionsForOrganization(organizationId)),
    );

    readonly availableRegistries$ = this._getAvailableRegistries$(
        this.currentOrganizationPermissions$,
    );

    readonly overviewRegistryPermissions$: Observable<string[]> =
        this.searchCurrentOrganizationPermissions({
            applicationInstances: [APP_INSTANCE_LOOKUP.overview],
        }).pipe(
            map(permissions =>
                permissions
                    .map(permission => parseProductCode(permission.product)?.id)
                    .filter((registryId): registryId is string => registryId !== null),
            ),
        );

    readonly canAccessOverview$ = this.overviewRegistryPermissions$.pipe(
        map(permissions => permissions.length > 0),
    );

    constructor(
        @Inject(LG_APP_INFO) private _lgAppInfo: IAppInfo,
        @Inject(LG_USER_INFO) public _lgUserInfo: SharedAppUser,
    ) {
        this.userProfile$.subscribe(userProfile => {
            this._matomo.setUserID(userProfile.email);
            this._lgUserInfo.name = `${userProfile.firstName} ${userProfile.lastName}`;
            this._lgUserInfo.email = userProfile.email;
            this._lgUserInfo.impersonator = userProfile.impersonation?.originalUserEmail;
            this._lgUserInfo.impersonatorName = userProfile.impersonation?.originalUserName;
        });

        combineLatest([this.organizationId$, this.userProfile$]).subscribe(
            ([organizationId, userProfile]) => {
                const organization = userProfile.organizations.find(
                    org => org.cicCode === organizationId,
                );

                if (organization) {
                    this._tracer.setOrganization(organization.organizationId);
                    this._tracer.setPosition(organization.position);
                    this._tracer.setOrganizationName(organization.name);
                }
            },
        );
    }

    getPermissionsForOrganization(
        organizationId: number,
    ): Observable<AuthorizationExtendedPermission[]> {
        return this.permissionsByOrganization$.pipe(
            map(permissionsByOrganization => permissionsByOrganization[organizationId]),
        );
    }

    searchCurrentOrganizationPermissions({
        applicationInstances,
        products,
    }: {
        applicationInstances?: string[];
        products?: string[];
    } = {}): Observable<AuthorizationExtendedPermission[]> {
        return this.currentOrganizationPermissions$.pipe(
            map(this._filterPermissions({ applicationInstances, products })),
        );
    }

    getListOfRegistryInfoFromAuth(app?: InsightsApplication): Observable<AuthRegistryInfo[]> {
        const applicationInstances = [];

        if (app) {
            const appInstance =
                this._sharedConfigService.getCountry() === "CA"
                    ? APP_CA_INSTANCE_LOOKUP[app]
                    : APP_INSTANCE_LOOKUP[app];
            if (appInstance) {
                applicationInstances.push(appInstance);
            }
        }

        return this.searchCurrentOrganizationPermissions({
            applicationInstances,
        }).pipe(map(permissions => getUniqRegistriesFromPermissions(permissions)));
    }

    getRegistriesGroupedByTenant(app?: InsightsApplication): Observable<Tenants> {
        return this.getListOfRegistryInfoFromAuth(app).pipe(
            map(registries => {
                const registriesGroupedByTenant: Dictionary<AuthRegistryInfo[]> = groupBy(
                    registries,
                    registry => registry.tenant,
                );
                // Until lodash Dictionary allows specifying the key type or there is a better groupBy function
                return registriesGroupedByTenant as Tenants;
            }),
        );
    }

    getTenantRegistries(
        registryTenant: RegistryTenant,
        app?: CodmanApplication,
    ): Observable<AuthRegistryInfo[]> {
        return this.getRegistriesGroupedByTenant(app).pipe(
            map(tenants => tenants[registryTenant] ?? []),
        );
    }

    getRegistryInfo(
        registryId: string,
        useCurrentAppInstance = false,
    ): Observable<AuthRegistryInfo | undefined> {
        return this.searchCurrentOrganizationPermissions({
            products: [createProductCode(registryId)],
            applicationInstances:
                useCurrentAppInstance && this.currentAppInstance
                    ? [this.currentAppInstance]
                    : undefined,
        }).pipe(map(permissions => getUniqRegistriesFromPermissions(permissions)[0]));
    }

    getOrganizationsByRegistryId(
        registry: string | null,
        app?: CodmanApplication,
    ): Observable<AuthorizationOrganization[]> {
        const applicationInstances: string[] = [];
        const codmanApp = app ?? (this._lgAppInfo.productId as CodmanApplication);
        let codmanInstance = "";
        if (codmanApp) {
            codmanInstance =
                this._sharedConfigService.getCountry() === "CA"
                    ? APP_CA_INSTANCE_LOOKUP[codmanApp]
                    : APP_INSTANCE_LOOKUP[codmanApp];
        } else {
            codmanInstance = "";
        }
        const appInstance = codmanInstance;

        if (appInstance) {
            applicationInstances.push(appInstance);
        }

        return this._getRegistryOrganizations$(registry, applicationInstances);
    }

    setDefaultOrganization(
        organizations: AuthorizationOrganization[],
        organizationIdFromQueryParams: string | null,
    ): void {
        if (organizationIdFromQueryParams != null) {
            const isOrganizationIdValid = this._validateAndSetOrganizationId(
                organizationIdFromQueryParams,
                organizations,
            );
            if (isOrganizationIdValid) {
                return;
            }
        }

        const organizationIdFromLocalStorage = localStorage.getItem(organizationIdUrlParam);
        const organization = organizations.find(
            o => o.organizationId === Number(organizationIdFromLocalStorage),
        );
        if (organizationIdFromLocalStorage != null && organization != null) {
            const isOrganizationIdValid = this._validateAndSetOrganizationId(
                organization.organizationId,
                organizations,
            );
            if (isOrganizationIdValid) {
                return;
            }
        }

        const firstValidOrganization = organizations.find(org => org.cicCode != null);
        if (firstValidOrganization) {
            this.setOrganizationId(firstValidOrganization.cicCode);
            return;
        }

        // if there are no valid organizations in the profile
        this._router.navigate(["access-denied"]);
    }

    setOrganizationId(organizationId: number | null): void {
        this._updateOrganization(organizationId);
    }

    getOrganizationsDropdownDefinition(
        registryId: string | null = null,
        { isNullWhenNoSelectionPossible } = { isNullWhenNoSelectionPossible: false },
    ): Observable<IDropdownDefinition<number> | null> {
        return this.getOrganizationsByRegistryId(registryId).pipe(
            map(organizations => {
                const organizationsWithCic = organizations.filter(
                    organization => organization.cicCode != null,
                );

                if (organizationsWithCic.length < 2 && isNullWhenNoSelectionPossible) {
                    return null;
                }

                return {
                    groups: [
                        {
                            entries: organizationsWithCic.map(organization => ({
                                id: organization.cicCode,
                                name: organization.name,
                            })),
                        },
                    ],
                };
            }),
        );
    }

    private _getAllUserOrganizations$(): Observable<AuthorizationOrganization[]> {
        return this._authorizationApiService
            .getOrganizations({
                applicationInstance: this.currentAppInstance,
            })
            .pipe(
                shareReplay(1),
                catchError(err => {
                    console.error(
                        `Authorization API threw error: ${err?.error?.ErrorCode} ${err?.error?.ErrorId}`,
                    );
                    return of([]);
                }),
            );
    }

    private _getRegistryOrganizations$(
        registry: string | null,
        applicationInstances?: string[],
    ): Observable<AuthorizationOrganization[]> {
        const products = registry != null ? [createProductCode(registry)] : [];
        const instances =
            this._sharedConfigService.getCountry() === "CA"
                ? ALL_CODMAN_CA_APP_INSTANCES
                : ALL_CODMAN_APP_INSTANCES;

        return this.allOrganizationsPermissions$.pipe(
            map(
                this._filterPermissions({
                    applicationInstances: applicationInstances ?? instances,
                    products,
                }),
            ),
            catchError(err => {
                console.error(
                    `Authorization API threw error: ${err?.error?.ErrorCode} ${err?.error?.ErrorId}`,
                );
                return of([]);
            }),
            map(permission => uniqBy(permission, "cicCode")),
        );
    }

    private _filterPermissions({
        applicationInstances,
        products,
    }: {
        applicationInstances?: string[];
        products?: string[];
    }): (permissions: AuthorizationExtendedPermission[]) => AuthorizationExtendedPermission[] {
        return permissions =>
            permissions.filter(permission => {
                let containsProduct = true;
                if (products?.length) {
                    containsProduct = products.includes(permission.product);
                }
                let containsAppInstance = true;
                if (applicationInstances?.length) {
                    containsAppInstance = applicationInstances.includes(
                        permission.applicationInstance,
                    );
                }
                return containsProduct && containsAppInstance;
            });
    }

    private _getAvailableRegistries$(
        currentOrganizationPermissions$: Observable<AuthorizationExtendedPermission[]>,
    ): Observable<string[]> {
        return currentOrganizationPermissions$.pipe(
            map(permissions => {
                if (this.currentAppInstance) {
                    permissions = permissions.filter(
                        permission => permission.applicationInstance === this.currentAppInstance,
                    );
                }

                return permissions
                    .map(permission => parseProductCode(permission.product)?.id)
                    .filter((registry): registry is string => registry != null)
                    .slice()
                    .sort((firstRegistry, secondRegistry) =>
                        firstRegistry.localeCompare(secondRegistry),
                    );
            }),
        );
    }

    private _validateAndSetOrganizationId(
        organizationId: number | string,
        organizations: AuthorizationOrganization[],
    ): boolean {
        if (typeof organizationId === "string") {
            organizationId = Number(organizationId);
        }
        const organization = organizations.find(o => o.organizationId === organizationId);

        if (organization != null) {
            this.setOrganizationId(organization.cicCode);
            return true;
        }
        return false;
    }

    private _updateOrganization(organizationId: number | null): void {
        this.userOrganizations$.pipe(first()).subscribe(organizations => {
            const foundOrganization = organizations.find(
                organization => organizationId != null && organization.cicCode === organizationId,
            );

            if (foundOrganization) {
                this._setOrganization(foundOrganization);
                return;
            }

            const defaultOrganization = organizations.find(
                organization => organization.cicCode != null,
            );

            if (defaultOrganization) {
                this._setOrganization(defaultOrganization);
                return;
            }

            this._setOrganization(null);
        });
    }

    private _setOrganization(organization: AuthorizationOrganization | null): void {
        if (organization?.cicCode != null) {
            this._organizationId$.next(organization.cicCode);
            this._organizationLgId$.next(organization.organizationId);
            this._organizationName$.next(organization.name);
            this._organizationUserPosition$.next(organization.position);
            localStorage.setItem(organizationIdUrlParam, String(organization.organizationId));

            this._router.navigate([], {
                queryParams: { [organizationIdUrlParam]: organization.organizationId },
                queryParamsHandling: "merge",
            });
        } else {
            this._organizationId$.complete();
            this._organizationLgId$.complete();
            this._organizationName$.complete();
            this._organizationUserPosition$.complete();
            localStorage.removeItem(organizationIdUrlParam);
        }
    }

    private _getMrdmProducts$(): Observable<IProduct[]> {
        return combineLatest([
            this._getAllowedAppInstances(),
            this._sharedConfigService.configuration$,
        ]).pipe(
            map(([allowedAppInstances, config]) => {
                return MRDM_PRODUCTS.filter(product =>
                    this._isAtLeastOneInstanceAvailable(product.appInstances, config),
                )
                    .filter(product =>
                        this._isAtLeastOneInstanceAllowed(
                            product.appInstances,
                            allowedAppInstances,
                        ),
                    )
                    .map(product => ({
                        id: product.id,
                        name:
                            product.name ??
                            this._lgTranslateService.translate(product.nameLc ?? ""),
                        url: product.getUrl(config),
                        shortDescription: product.shortDescription,
                        iconClass: product.iconClass,
                    }));
            }),
            shareReplay(1),
        );
    }

    private _getAllowedAppInstances(): Observable<string[]> {
        const applicationInstances = flatten(MRDM_PRODUCTS.map(product => product.appInstances));
        return this._authorizationApiService.searchPermissions({ applicationInstances }).pipe(
            map(permissions => {
                const appInstances = permissions.map(permission => permission.applicationInstance);
                const uniqueAppInstances = uniq(appInstances);
                return uniqueAppInstances;
            }),
        );
    }

    private _isAtLeastOneInstanceAllowed(
        appInstances: string[],
        allowedAppInstances: string[],
    ): boolean {
        return appInstances.some(appInstance => allowedAppInstances.includes(appInstance));
    }

    private _isAtLeastOneInstanceAvailable(
        appInstances: string[],
        config: AppConfiguration,
    ): boolean {
        if (config.tenant === "idr")
            return appInstances.some(appInstance => appInstance.includes(".CA"));
        return true;
    }

    private _getAllOrganizationsPermissions$(
        allUserOrganizations$: Observable<AuthorizationOrganization[]>,
    ): Observable<AuthorizationExtendedPermission[]> {
        const instances =
            this._sharedConfigService.getCountry() === "CA"
                ? ALL_CODMAN_CA_APP_INSTANCES
                : ALL_CODMAN_APP_INSTANCES;
        return allUserOrganizations$.pipe(
            switchMap(organizations => {
                const organizationIds = organizations
                    .map(organization => organization.cicCode)
                    .filter((organizationId): organizationId is number => organizationId != null)
                    .map(organizationId => organizationId.toString());

                return this._authorizationApiService.searchPermissions({
                    organizations: organizationIds,
                    organizationIdentifierType: "Cic",
                    applicationInstances: instances,
                });
            }),
            shareReplay(1),
        );
    }
}
